import { styled } from '@liftfoils/styles'
import { SectionProductVideoProps } from '@liftfoils/models'

import {
  Button,
  PortableText,
  YouTubePlayer,
  VideoModalContentWrap,
  Gradient,
  Modal,
  Media,
} from '@liftfoils/components'
import { FC, useState } from 'react'
import { getYouTubeIdFromUrl } from '@liftfoils/utils'

const Wrap = styled('div', {
  position: 'relative',
  display: 'grid',
  $posterHeight: true,
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

const Overlay = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  zIndex: 1,
  display: 'grid',
  gridTemplateRows: '1fr min-content 1fr',
  alignItems: 'center',
  $py: 'XL',
  px: '$containerMargin',
  gridGap: '$10',
})

const Title = styled('h2', {
  lift_font: 'caps02',
  color: 'white',
  textAlign: 'center',
})

const CenterWrap = styled('div', {
  display: 'grid',
  alignContent: 'center',
  justifyItems: 'center',
  gridRowStart: 2,
})

const BottomWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '$2',
  rowGap: '$8',
  alignSelf: 'end',
  gridRowStart: 3,
  '@md': {
    gridTemplateColumns: 'repeat(3, min-content)',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '$10',
  },
})

const ButtonWrap = styled('div', {
  display: 'inline-flex',
  justifyContent: 'center',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  order: 1,
  '@md': {
    display: 'block',
    gridColumnStart: 'initial',
    gridColumnEnd: 'initial',
    order: 2,
  },
})

const Label = styled('div', {
  display: 'block',
  order: 2,
  color: '$white_08',
  lift_font: 'body05',
  textAlign: 'center',
  '.highlighted': {
    color: 'white',
  },
  variants: {
    singleLabel: {
      true: {
        gridColumnStart: 1,
        gridColumnEnd: 3,
      },
    },
  },
  '@sm': {
    minWidth: '146px',
  },
})

export const SectionProductVideo: FC<SectionProductVideoProps> = ({
  title,
  background,
  leftLabel,
  buttonLabel,
  rightLabel,
  priority,
  modalVideo,
}) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)

  return (
    <>
      <Wrap>
        {background && (
          <MediaWrap>
            <Media
              {...background}
              layout={'fill'}
              priority={priority}
              sizes="100vw"
              hardcropRatio={{
                '856': 'square',
                '1440': 'landscape2',
                '1920': 'landscape2',
                '2880': 'landscape1',
              }}
              controlsPosition={'bottom'}
              isVideoPaused={isVideoModalOpen}
              alt={background.alt ?? ''}
            />
            <Gradient from={'bottom'} css={{ height: '50%' }} />
          </MediaWrap>
        )}
        <Overlay>
          <CenterWrap>{title && <Title>{title}</Title>}</CenterWrap>

          <BottomWrap>
            {leftLabel && (
              <Label singleLabel={!rightLabel}>
                <PortableText value={leftLabel} />
              </Label>
            )}
            {buttonLabel && modalVideo && (
              <ButtonWrap>
                <Button
                  appearance={'blur'}
                  size={'medium'}
                  onClick={() => setVideoModalOpen(true)}
                >
                  {buttonLabel}
                </Button>
              </ButtonWrap>
            )}
            {rightLabel && (
              <Label singleLabel={!leftLabel}>
                <PortableText value={rightLabel} />
              </Label>
            )}
          </BottomWrap>
        </Overlay>
      </Wrap>
      {modalVideo && (
        <Modal
          isOpen={isVideoModalOpen}
          onRequestClose={() => setVideoModalOpen(false)}
          overlayCloseButton
          variant={'rounded'}
          overlayVariant="blur"
        >
          <VideoModalContentWrap>
            <YouTubePlayer videoId={getYouTubeIdFromUrl(modalVideo.url)} />
          </VideoModalContentWrap>
        </Modal>
      )}
    </>
  )
}
